<template>
  <el-dialog
    :close-on-click-modal="false"
    :title="title"
    :visible.sync="dialogFormVisible"
    width="550px"
    @close="close"
  >
    <el-form ref="form" label-width="100px" :model="form" :rules="rules">
      <el-form-item style="margin-bottom: 20px" label="退货类型" prop="reason">
        <el-input v-model="form.reason" size="small" />
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="排序" prop="sort_num">
        <el-input-number v-model="form.sort_num" controls-position="right" :min="1" step-strictly size="small" />
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="是否开启" prop="is_active">
        <el-switch
            v-model="form.is_active"
            active-text="开启"
            :active-value="1"
            inactive-text="禁用"
            :inactive-value="0"
        />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close" size="small">取 消</el-button>
      <el-button type="primary" @click="save('form')" size="small">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
  import {apiAddReturnReason} from "@/request/api";

  export default {
    name: 'Edit',
    data() {
      return {
        form: {
          name: '',
          username: '',
          state: '1',
        },
        rules: {
          reason: [
            { required: true, trigger: 'blur', message: '请输入退货类型' },
          ],
          sort_num: [
            { required: true, trigger: 'blur', message: '请输入排序' },
          ],
        },
        title: '',
        dialogFormVisible: false,
      }
    },
    created() {},
    methods: {
      showEdit(row) {
        if (!row) {
          this.title = '添加'
        } else {
          this.title = '修改'
          this.form = Object.assign({}, row)
        }
        this.dialogFormVisible = true
      },
      close() {
        this.$refs['form'].resetFields()
        this.dialogFormVisible = false
      },
      save(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            apiAddReturnReason(this.form).then(res => {
              if(res.code == 200){
                this.$message({
                  type: 'success',
                  message: '操作成功!'
                })
                this.dialogFormVisible = false
                this.$emit('refresh')
              }else{
                this.$message({
                  message: res.message,
                  type: 'error'
                })
              }

            })
          } else {
            return false;
          }
        });
      },
    },
  }
</script>
