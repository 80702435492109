<template>
  <div class="page">
    <div class="pageFucView">
      <div class="pageFucView_left">
        <el-button class="button" type="primary" icon="el-icon-plus" @click="handleEdit">新增</el-button>
      </div>
      <div class="pageFucView_right">
      </div>
    </div>

    <el-table
        :data="list"
        style="width: 100%"
        border>
      <el-table-column
          align="center"
          prop="id"
          label="ID"
          width="80">
      </el-table-column>
      <el-table-column
          align="center"
          prop="reason"
          label="原因类型">
      </el-table-column>
      <el-table-column
          align="center"
          prop="sort_num"
          label="排序">
      </el-table-column>
      <el-table-column
          align="center"
          label="是否启用"
          prop="is_active"
          show-overflow-tooltip
      >
        <template #default="{ row }">
          <el-tooltip
              :content="row.is_active == 1 ? '点击禁用' : '点击启用'"
              :enterable="false"
              placement="top"
          >
            <el-switch
                v-model="row.is_active"
                :active-value="1"
                :inactive-value="0"
                :value="true"
                @change="changeStatus(row.id, row.is_active)"
            />
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作" width="250">
        <template #default="{ row }">
          <el-button type="text" @click="handleEdit(row)">修改</el-button>
          <el-button type="text" @click="handleDelete(row.id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <Edit ref="Edit" @refresh="getData"/>
  </div>
</template>
<script>
import Edit from './components/Edit'
import {apiGetReturnReasonTypeList, apiModifyReturnReason,apiDeleteReturnReason} from "@/request/api";

export default {
  // 时间戳转换
  filters: {
    formatDate(time) {
      var date = new Date(time * 1000);
      return date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
    }
  },
  components: { Edit },
  props: [],
  data() {
    return {
      selectRows: '',
      pageCount: 0,
      list: [],
    }
  },
  computed: {},
  watch: {},
  created() {
    this.getData()
  },
  methods: {
    getData(){
      apiGetReturnReasonTypeList().then(res => {
        if(res.code == 200){
          this.list = res.data
        }
      })
    },
    changeStatus(id, is_active){
      apiModifyReturnReason({id, is_active}).then(res => {
        if(res.code == 200){
          this.getData()
        }else{
          this.$message({
            message: res.message,
            type: 'error'
          })
        }
      })
    },
    handleEdit(row) {
      if (row.id) {
        this.$refs['Edit'].showEdit(row)
      } else {
        this.$refs['Edit'].showEdit()
      }
    },
    handleDelete(id){
      this.$confirm('是否删除该原因', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        apiDeleteReturnReason({id}).then(res => {
          if(res.code == 200){
            this.getData()
          }else{
            this.$message({
              message: res.message,
              type: 'error'
            })
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    }
  }
}

</script>
<style>
.page {
  background-color: #FFF;
  padding: 20px;
  margin: 20px;
  margin-bottom: 80px;
}
.pageView {
  display: flex;
  justify-content: flex-end;
}
.pageFucView {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.pageFucView_right {
  display: flex;
}
.el-form-item {
  margin-bottom: 0px;
  margin-right: 10px;
}
</style>
